import ReactGA from 'react-ga4';

const MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID;

export const initializeAnalytics = () => {
  if (MEASUREMENT_ID && MEASUREMENT_ID !== 'G-XXXXXXXXXX') {
    ReactGA.initialize(MEASUREMENT_ID, {
      debug: import.meta.env.DEV,
      testMode: import.meta.env.DEV,
      gtagOptions: {
        send_page_view: true
      }
    });

    // Send initial pageview
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname
    });

    if (import.meta.env.DEV) {
      console.log('Google Analytics initialized with ID:', MEASUREMENT_ID);
    }
  } else {
    console.warn('Google Analytics Measurement ID not configured');
  }
};

export const trackEvent = (
  category: string,
  action: string,
  label?: string,
  value?: number
) => {
  if (MEASUREMENT_ID && MEASUREMENT_ID !== 'G-XXXXXXXXXX') {
    ReactGA.event({
      category,
      action,
      label,
      value,
    });

    // Log events in development
    if (import.meta.env.DEV) {
      console.log('GA Event:', { category, action, label, value });
    }
  }
};

export const trackPageView = (path: string) => {
  if (MEASUREMENT_ID && MEASUREMENT_ID !== 'G-XXXXXXXXXX') {
    ReactGA.send({
      hitType: "pageview",
      page: path
    });

    // Log pageviews in development
    if (import.meta.env.DEV) {
      console.log('GA Pageview:', path);
    }
  }
};

export const ANALYTICS_EVENTS = {
  AUTH: {
    SIGNUP: 'signup',
    LOGIN: 'login',
    SOCIAL_LOGIN: 'social_login',
    SIGNOUT: 'signout'
  },
  LINKS: {
    CREATE: 'create_link',
    SHARE: 'share_link',
    CLICK: 'click_link',
    REDIRECT: 'redirect_complete'
  },
  EARNINGS: {
    WITHDRAWAL_REQUEST: 'withdrawal_request',
    PAYOUT_RECEIVED: 'payout_received',
  },
  USER: {
    AGE_VERIFY: 'age_verify',
    TERMS_ACCEPT: 'terms_accept',
    PROFILE_UPDATE: 'profile_update',
  },
} as const;