// Environment variables
export const config = {
  supabaseUrl: import.meta.env.VITE_SUPABASE_URL,
  supabaseAnonKey: import.meta.env.VITE_SUPABASE_ANON_KEY,
  stripePublicKey: import.meta.env.VITE_STRIPE_PUBLIC_KEY,
  platformFeePercentage: Number(import.meta.env.VITE_PLATFORM_FEE_PERCENTAGE) || 30,
  environment: import.meta.env.MODE,
  isProduction: import.meta.env.PROD,
  version: '1.0.0',
};

// System constants
export const SYSTEM = {
  minimumWithdrawalAmount: 20,
  userCommissionPercentage: 70, // User gets 70% of commission
  supportEmail: 'support@easyearn.app',
};

// Integration providers
export const PROVIDERS = {
  STRIPE: 'stripe',
  AMAZON: 'amazon_associates',
} as const;

// Amazon configuration
export const AMAZON_CONFIG = {
  baseUrl: 'https://webservices.amazon.com/paapi5',
  region: 'us-east-1',
  marketplace: 'www.amazon.com',
  partnerTag: import.meta.env.VITE_AMAZON_PARTNER_TAG || '',
} as const;

// Error messages
export const ERRORS = {
  MAINTENANCE: 'System is currently under maintenance',
  UNAUTHORIZED: 'You are not authorized to perform this action',
  INVALID_CREDENTIALS: 'Invalid credentials provided',
  CONNECTION_FAILED: 'Failed to connect to the service',
  LINK_GENERATION: {
    PRODUCT_NOT_FOUND: 'The selected product is no longer available',
    CREDENTIALS_MISSING: 'Platform configuration issue. Please contact support.',
    GENERAL: 'Failed to generate link. Please try again.',
  },
} as const;