import React, { useEffect, useState } from 'react';
import { Users, Link, DollarSign, Calendar, Download } from 'lucide-react';
import { format } from 'date-fns';
import { supabase } from '../../lib/supabase';
import { log } from '../../lib/logger';

interface UserReport {
  id: string;
  email: string;
  created_at: string;
  link_count: number;
  total_earnings: number;
  total_withdrawn: number;
}

interface ReportStats {
  totalUsers: number;
  totalLinks: number;
  totalEarnings: number;
  totalWithdrawn: number;
  averageLinksPerUser: number;
}

export function ReportPage() {
  const [users, setUsers] = useState<UserReport[]>([]);
  const [stats, setStats] = useState<ReportStats>({
    totalUsers: 0,
    totalLinks: 0,
    totalEarnings: 0,
    totalWithdrawn: 0,
    averageLinksPerUser: 0
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    checkAdminAndFetchData();
  }, []);

  const checkAdminAndFetchData = async () => {
    try {
      // First verify the current user is an admin
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('is_admin')
        .eq('id', user.id)
        .single();

      if (userError) throw userError;
      if (!userData?.is_admin) throw new Error('Not authorized');

      await fetchReportData();
    } catch (error) {
      log('error', 'Admin verification failed', { error });
      setError(error instanceof Error ? error.message : 'Access denied');
      setLoading(false);
    }
  };

  const fetchReportData = async () => {
    try {
      // Get all users with their stats
      const { data: usersData, error: usersError } = await supabase
        .from('users')
        .select(`
          id,
          email,
          created_at,
          affiliate_links (count),
          earnings!earnings_user_id_fkey (
            amount,
            status
          )
        `);

      if (usersError) throw usersError;

      // Process user data
      const processedUsers: UserReport[] = usersData.map(user => {
        const totalEarnings = user.earnings?.reduce((sum: number, e: any) => 
          sum + Number(e.amount), 0) || 0;
        
        const totalWithdrawn = user.earnings?.reduce((sum: number, e: any) => 
          e.status === 'paid' ? sum + Number(e.amount) : sum, 0) || 0;

        return {
          id: user.id,
          email: user.email || '',
          created_at: user.created_at,
          link_count: user.affiliate_links?.count || 0,
          total_earnings: totalEarnings,
          total_withdrawn: totalWithdrawn
        };
      });

      // Calculate overall stats
      const stats: ReportStats = {
        totalUsers: processedUsers.length,
        totalLinks: processedUsers.reduce((sum, user) => sum + user.link_count, 0),
        totalEarnings: processedUsers.reduce((sum, user) => sum + user.total_earnings, 0),
        totalWithdrawn: processedUsers.reduce((sum, user) => sum + user.total_withdrawn, 0),
        averageLinksPerUser: processedUsers.length > 0 
          ? processedUsers.reduce((sum, user) => sum + user.link_count, 0) / processedUsers.length 
          : 0
      };

      setUsers(processedUsers);
      setStats(stats);
      
      log('info', 'Admin report data fetched successfully');
    } catch (error) {
      log('error', 'Failed to fetch report data', { error });
      setError('Failed to load report data');
    } finally {
      setLoading(false);
    }
  };

  const exportToCsv = () => {
    const headers = ['Sign Up Date', 'UUID', 'Email', 'Links Generated', 'Total Earnings', 'Total Withdrawn'];
    const csvData = users.map(user => [
      format(new Date(user.created_at), 'yyyy-MM-dd HH:mm:ss'),
      user.id,
      user.email,
      user.link_count,
      user.total_earnings.toFixed(2),
      user.total_withdrawn.toFixed(2)
    ]);

    const csvContent = [
      headers.join(','),
      ...csvData.map(row => row.join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `easyearn-report-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 text-center">
          <h2 className="text-2xl font-bold mb-2">Access Denied</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="mb-8 flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">Admin Report</h1>
        <button
          onClick={exportToCsv}
          className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          <Download className="h-5 w-5" />
          Export to CSV
        </button>
      </div>

      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-blue-100 rounded-lg">
              <Users className="h-6 w-6 text-blue-600" />
            </div>
            <div>
              <p className="text-2xl font-bold text-gray-900">{stats.totalUsers}</p>
              <p className="text-gray-600">Total Users</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-green-100 rounded-lg">
              <Link className="h-6 w-6 text-green-600" />
            </div>
            <div>
              <p className="text-2xl font-bold text-gray-900">{stats.totalLinks}</p>
              <p className="text-gray-600">Total Links</p>
              <p className="text-sm text-gray-500">
                Avg: {stats.averageLinksPerUser.toFixed(1)} per user
              </p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-purple-100 rounded-lg">
              <DollarSign className="h-6 w-6 text-purple-600" />
            </div>
            <div>
              <p className="text-2xl font-bold text-gray-900">
                ${stats.totalEarnings.toFixed(2)}
              </p>
              <p className="text-gray-600">Total Earnings</p>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex items-center gap-4">
            <div className="p-3 bg-indigo-100 rounded-lg">
              <DollarSign className="h-6 w-6 text-indigo-600" />
            </div>
            <div>
              <p className="text-2xl font-bold text-gray-900">
                ${stats.totalWithdrawn.toFixed(2)}
              </p>
              <p className="text-gray-600">Total Withdrawn</p>
            </div>
          </div>
        </div>
      </div>

      {/* Users Table */}
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="px-6 py-4 border-b border-gray-200">
          <h2 className="text-lg font-semibold text-gray-900">User Details</h2>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Sign Up Date
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Email
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  UUID
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Links
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Earnings
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Withdrawn
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {users.map((user) => (
                <tr key={user.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {format(new Date(user.created_at), 'MMM d, yyyy HH:mm')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {user.email}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-mono text-gray-500">
                    {user.id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {user.link_count}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    ${user.total_earnings.toFixed(2)}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    ${user.total_withdrawn.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}