import React from 'react';
import CookieConsent from 'react-cookie-consent';
import { Cookie } from 'lucide-react';
import { initializeAnalytics } from '../lib/analytics';

export function CookieBanner() {
  return (
    <CookieConsent
      location="bottom"
      buttonText="Accept"
      declineButtonText="Decline"
      enableDeclineButton
      onAccept={() => {
        // Enable analytics when cookies are accepted
        window.localStorage.setItem('cookies-accepted', 'true');
        initializeAnalytics();
      }}
      style={{
        background: '#2B3137',
        alignItems: 'center',
        padding: '1rem',
      }}
      buttonStyle={{
        background: '#3B82F6',
        color: 'white',
        fontSize: '14px',
        padding: '8px 16px',
        borderRadius: '6px',
      }}
      declineButtonStyle={{
        background: 'transparent',
        border: '1px solid #9CA3AF',
        color: 'white',
        fontSize: '14px',
        padding: '8px 16px',
        borderRadius: '6px',
      }}
    >
      <div className="flex items-center gap-3">
        <Cookie className="h-6 w-6 text-blue-400" />
        <p>
          We use cookies to enhance your experience and analyze our traffic. 
          By clicking "Accept", you agree to our use of cookies.
        </p>
      </div>
    </CookieConsent>
  );
}