import { supabase } from './supabase';
import { LOG_LEVELS } from './config';

type LogLevel = typeof LOG_LEVELS[keyof typeof LOG_LEVELS];

export async function log(
  level: LogLevel,
  message: string,
  context: Record<string, any> = {}
) {
  try {
    // Remove sensitive data
    const sanitizedContext = JSON.parse(JSON.stringify(context));
    delete sanitizedContext.password;
    delete sanitizedContext.token;
    delete sanitizedContext.apiKey;

    // Add timestamp and environment info
    const enhancedContext = {
      ...sanitizedContext,
      timestamp: new Date().toISOString(),
      environment: import.meta.env.MODE,
    };

    // Log to database
    await supabase
      .from('system_logs')
      .insert({
        level,
        message,
        context: enhancedContext,
      });

    // Also log to console in development
    if (import.meta.env.DEV) {
      console.log(`[${level.toUpperCase()}] ${message}`, enhancedContext);
    }
  } catch (error) {
    // Fallback to console logging if database logging fails
    console.error('Logging failed:', error);
    console.log(`[${level.toUpperCase()}] ${message}`, context);
  }
}