import { createClient } from '@supabase/supabase-js';
import { log } from './logger';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

// Create a single, simple client instance
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    storage: window.localStorage,
    autoRefreshToken: true,
    detectSessionInUrl: true
  }
});

// Initialize function to verify connection and auth state
export async function initializeSupabase(): Promise<boolean> {
  try {
    // Test connection by making a simple query
    const { error: pingError } = await supabase
      .from('users')
      .select('id')
      .limit(1);

    if (pingError) {
      log('error', 'Failed to connect to Supabase', { error: pingError });
      return false;
    }

    // Get current auth state
    const { data: { session }, error: authError } = await supabase.auth.getSession();
    if (authError) {
      log('error', 'Failed to get auth session', { error: authError });
      return false;
    }

    // If we have a session, update last login
    if (session?.user) {
      const { error: updateError } = await supabase
        .from('users')
        .update({ last_login: new Date().toISOString() })
        .eq('id', session.user.id);

      if (updateError) {
        log('error', 'Failed to update last login', { error: updateError });
        // Don't return false here as this is not critical
      }
    }

    log('info', 'Supabase initialized successfully', {
      hasSession: !!session
    });

    return true;
  } catch (error) {
    log('error', 'Failed to initialize Supabase', { error });
    return false;
  }
}

// Set up auth state change listener
supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'SIGNED_IN') {
    // Update last_login timestamp
    if (session?.user?.id) {
      supabase
        .from('users')
        .update({ last_login: new Date().toISOString() })
        .eq('id', session.user.id)
        .then(() => console.log('Updated last login'))
        .catch(err => console.error('Failed to update last login:', err));
    }
  } else if (event === 'SIGNED_OUT') {
    // Clear any cached data
    window.localStorage.clear();
  }
});