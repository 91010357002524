import React from 'react';
import { Link } from 'react-router-dom';
import { LogIn, ArrowRight } from 'lucide-react';

export function SignOutPage() {
  return (
    <div className="min-h-[80vh] flex items-center justify-center px-4">
      <div className="max-w-md w-full text-center">
        <h1 className="text-3xl font-bold text-gray-900 mb-4">
          👋 See you soon!
        </h1>
        <p className="text-gray-600 mb-8">
          You've been successfully signed out. Ready to start earning again?
        </p>
        <div className="space-y-4">
          <Link
            to="/login"
            className="block w-full bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors flex items-center justify-center gap-2"
          >
            <LogIn className="h-5 w-5" />
            Sign Back In
          </Link>
          <Link
            to="/"
            className="block w-full text-gray-600 hover:text-gray-900 transition-colors flex items-center justify-center gap-2"
          >
            Return Home
            <ArrowRight className="h-5 w-5" />
          </Link>
        </div>
      </div>
    </div>
  );
}