import React from 'react';

export function Footer() {
  return (
    <footer className="bg-white border-t border-gray-100">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <p className="text-sm text-gray-500">
            EasyEarn is a toy - it's just a fun prototype! It should work but we don't guarantee it 🚧
          </p>
        </div>
      </div>
    </footer>
  );
}