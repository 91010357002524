import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Share2, Plus, LayoutDashboard, Settings } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { log } from '../../lib/logger';
import { trackEvent, ANALYTICS_EVENTS } from '../../lib/analytics';

export function Header() {
  const location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [signingOut, setSigningOut] = useState(false);

  useEffect(() => {
    checkAuth();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_OUT') {
        setUser(null);
        setIsAdmin(false);
        setSigningOut(false);
        if (location.pathname !== '/signout') {
          navigate('/signout');
        }
      } else if (session?.user) {
        setUser(session.user);
        await checkAdminStatus(session.user.id);
        setSigningOut(false);
      } else {
        setUser(null);
        setIsAdmin(false);
        setSigningOut(false);
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate, location.pathname]);

  const checkAuth = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      setUser(user);
      if (user) {
        await checkAdminStatus(user.id);
      }
    } catch (error) {
      log('error', 'Failed to check auth status', { error });
    }
  };

  const checkAdminStatus = async (userId: string) => {
    try {
      const { data } = await supabase
        .from('users')
        .select('is_admin')
        .eq('id', userId)
        .single();
      setIsAdmin(data?.is_admin || false);
    } catch (error) {
      log('error', 'Failed to check admin status', { error });
      setIsAdmin(false);
    }
  };

  const handleSignOut = async () => {
    if (signingOut) return;
    
    setSigningOut(true);
    try {
      trackEvent('Authentication', ANALYTICS_EVENTS.AUTH.SIGNOUT);
      await supabase.auth.signOut();
      localStorage.clear();
    } catch (error) {
      log('error', 'Sign out failed', { error });
      setSigningOut(false);
    }
  };

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    navigate(user ? '/dashboard' : '/');
  };

  return (
    <header className="bg-white shadow-sm">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex">
            <a 
              href="#"
              onClick={handleLogoClick}
              className="flex items-center"
            >
              <Share2 className="h-8 w-8 text-blue-500" />
              <span className="ml-2 text-xl font-bold text-gray-900">EasyEarn</span>
            </a>
          </div>
          <div className="flex items-center gap-4">
            {user ? (
              <>
                {isAdmin ? (
                  // Admin Navigation
                  <>
                    <Link
                      to="/admin"
                      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                        location.pathname === '/admin'
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      <LayoutDashboard className="h-5 w-5" />
                      Admin
                    </Link>
                    <Link
                      to="/admin/settings"
                      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                        location.pathname === '/admin/settings'
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      <Settings className="h-5 w-5" />
                      Settings
                    </Link>
                  </>
                ) : (
                  // Regular User Navigation
                  <>
                    <Link
                      to="/dashboard"
                      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                        location.pathname === '/dashboard'
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      <LayoutDashboard className="h-5 w-5" />
                      Dashboard
                    </Link>
                    <Link
                      to="/create-link"
                      className={`flex items-center gap-2 px-4 py-2 rounded-lg transition-colors ${
                        location.pathname === '/create-link'
                          ? 'bg-blue-50 text-blue-600'
                          : 'text-gray-600 hover:text-gray-900'
                      }`}
                    >
                      <Plus className="h-5 w-5" />
                      Create Link
                    </Link>
                  </>
                )}
                <button
                  onClick={handleSignOut}
                  disabled={signingOut}
                  className="text-gray-600 hover:text-gray-900 px-4 py-2 rounded-lg transition-colors disabled:opacity-50"
                >
                  Sign Out
                </button>
              </>
            ) : (
              <>
                <Link
                  to="/login"
                  className="text-gray-600 hover:text-gray-900 px-4 py-2 rounded-lg transition-colors"
                >
                  Sign In
                </Link>
                <Link
                  to="/signup"
                  className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors"
                >
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
}