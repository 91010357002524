import React, { useEffect, useState } from 'react';
import { Settings, AlertCircle, Check, X, RefreshCw, Key, Shield, ExternalLink } from 'lucide-react';
import { motion } from 'framer-motion';
import { supabase } from '../../lib/supabase';
import { PROVIDERS } from '../../lib/config';
import { log } from '../../lib/logger';
import { encryptData, decryptData } from '../../lib/crypto';

interface IntegrationCredentials {
  id: string;
  provider: string;
  credentials: string;
  last_verified: string | null;
}

interface ProviderConfig {
  name: string;
  fields: {
    key: string;
    label: string;
    type: string;
    required: boolean;
    placeholder: string;
  }[];
  instructions: string[];
}

const PROVIDER_CONFIGS: Record<string, ProviderConfig> = {
  [PROVIDERS.STRIPE]: {
    name: 'Stripe',
    fields: [
      {
        key: 'publishableKey',
        label: 'Publishable Key',
        type: 'text',
        required: true,
        placeholder: 'pk_live_...'
      },
      {
        key: 'secretKey',
        label: 'Secret Key',
        type: 'password',
        required: true,
        placeholder: 'sk_live_...'
      },
      {
        key: 'webhookSecret',
        label: 'Webhook Secret',
        type: 'password',
        required: true,
        placeholder: 'whsec_...'
      }
    ],
    instructions: [
      'Go to your Stripe Dashboard → Developers → API Keys',
      'Create a new restricted key with only the necessary permissions',
      'Set up a webhook endpoint in your Stripe Dashboard',
      'Use the webhook signing secret provided by Stripe'
    ]
  },
  [PROVIDERS.AMAZON]: {
    name: 'Amazon Associates',
    fields: [
      {
        key: 'accessKeyId',
        label: 'Access Key ID',
        type: 'text',
        required: true,
        placeholder: 'AKIA...'
      },
      {
        key: 'secretAccessKey',
        label: 'Secret Access Key',
        type: 'password',
        required: true,
        placeholder: 'Your secret access key'
      },
      {
        key: 'associateTag',
        label: 'Associate Tag',
        type: 'text',
        required: true,
        placeholder: 'yourtag-20'
      }
    ],
    instructions: [
      'Sign in to Amazon Associates Central',
      'Go to Tools → Product Advertising API',
      'Create API credentials if you haven\'t already',
      'Copy your Associate Tag from your Associates Central dashboard'
    ]
  }
};

export function IntegrationsPage() {
  const [credentials, setCredentials] = useState<Record<string, Record<string, string>>>({});
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState<string | null>(null);
  const [testing, setTesting] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    fetchCredentials();
  }, []);

  const fetchCredentials = async () => {
    try {
      const { data } = await supabase
        .from('integration_credentials')
        .select('*');

      if (data) {
        const decryptedCreds = data.reduce((acc, cred) => {
          try {
            acc[cred.provider] = JSON.parse(decryptData(cred.credentials));
          } catch (e) {
            log('error', `Failed to decrypt credentials for ${cred.provider}`, { error: e });
          }
          return acc;
        }, {} as Record<string, Record<string, string>>);

        setCredentials(decryptedCreds);
      }
    } catch (error) {
      log('error', 'Failed to fetch credentials', { error });
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (provider: string, field: string, value: string) => {
    setCredentials(prev => ({
      ...prev,
      [provider]: {
        ...(prev[provider] || {}),
        [field]: value
      }
    }));
  };

  const saveCredentials = async (provider: string) => {
    setSaving(provider);
    setError(null);

    try {
      const encryptedCreds = encryptData(JSON.stringify(credentials[provider]));

      const { error } = await supabase
        .from('integration_credentials')
        .upsert({
          provider,
          credentials: encryptedCreds,
          updated_at: new Date().toISOString()
        }, {
          onConflict: 'provider'
        });

      if (error) throw error;

      log('info', `Credentials updated for ${provider}`);
    } catch (error) {
      log('error', `Failed to save credentials for ${provider}`, { error });
      setError('Failed to save credentials. Please try again.');
    } finally {
      setSaving(null);
    }
  };

  const testConnection = async (provider: string) => {
    setTesting(provider);
    setError(null);

    try {
      // Implement actual API tests here
      await new Promise(resolve => setTimeout(resolve, 1500)); // Simulated API call
      
      await supabase
        .from('integration_credentials')
        .update({
          last_verified: new Date().toISOString()
        })
        .eq('provider', provider);

      log('info', `Connection tested successfully for ${provider}`);
    } catch (error) {
      log('error', `Connection test failed for ${provider}`, { error });
      setError('Connection test failed. Please verify your credentials.');
    } finally {
      setTesting(null);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
            <Settings className="h-6 w-6 text-blue-500" />
            Integration Settings
          </h2>
          <p className="mt-2 text-gray-600">
            Securely manage your integration credentials and test connections.
          </p>
        </div>

        {/* Security Notice */}
        <div className="p-6 bg-yellow-50 border-b border-yellow-100">
          <div className="flex items-start gap-4">
            <div className="p-2 bg-yellow-100 rounded-lg">
              <Shield className="h-6 w-6 text-yellow-600" />
            </div>
            <div>
              <h3 className="text-lg font-medium text-yellow-800">Security Notice</h3>
              <ul className="mt-2 text-sm text-yellow-700 space-y-1">
                <li>• All credentials are encrypted before storage</li>
                <li>• Access is restricted to admin users only</li>
                <li>• Regular security audits are performed</li>
                <li>• Use environment-specific credentials (test/production)</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="divide-y divide-gray-200">
          {Object.entries(PROVIDER_CONFIGS).map(([provider, config]) => (
            <div key={provider} className="p-6">
              <div className="mb-6">
                <h3 className="text-lg font-medium text-gray-900 flex items-center gap-2">
                  <Key className="h-5 w-5 text-blue-500" />
                  {config.name} Integration
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Configure your {config.name} credentials for secure integration.
                </p>
              </div>

              {/* Instructions */}
              <div className="mb-6 bg-gray-50 rounded-lg p-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">Setup Instructions:</h4>
                <ol className="text-sm text-gray-600 space-y-1">
                  {config.instructions.map((instruction, i) => (
                    <li key={i} className="flex items-start gap-2">
                      <span className="text-blue-500">{i + 1}.</span>
                      {instruction}
                    </li>
                  ))}
                </ol>
                <a
                  href={provider === PROVIDERS.STRIPE ? 'https://dashboard.stripe.com/apikeys' : 'https://affiliate-program.amazon.com/'}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="mt-4 inline-flex items-center gap-1 text-sm text-blue-600 hover:text-blue-700"
                >
                  Open Dashboard <ExternalLink className="h-4 w-4" />
                </a>
              </div>

              {/* Credential Fields */}
              <div className="space-y-4">
                {config.fields.map((field) => (
                  <div key={field.key}>
                    <label className="block text-sm font-medium text-gray-700">
                      {field.label}
                      {field.required && <span className="text-red-500">*</span>}
                    </label>
                    <input
                      type={field.type}
                      value={credentials[provider]?.[field.key] || ''}
                      onChange={(e) => handleInputChange(provider, field.key, e.target.value)}
                      placeholder={field.placeholder}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    />
                  </div>
                ))}
              </div>

              {/* Action Buttons */}
              <div className="mt-6 flex items-center gap-4">
                <button
                  onClick={() => saveCredentials(provider)}
                  disabled={saving === provider}
                  className="flex items-center gap-2 px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                >
                  {saving === provider ? (
                    <RefreshCw className="h-4 w-4 animate-spin" />
                  ) : (
                    <Check className="h-4 w-4" />
                  )}
                  Save Credentials
                </button>

                <button
                  onClick={() => testConnection(provider)}
                  disabled={testing === provider || !credentials[provider]}
                  className="flex items-center gap-2 px-4 py-2 text-gray-700 border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  {testing === provider ? (
                    <RefreshCw className="h-4 w-4 animate-spin" />
                  ) : (
                    <AlertCircle className="h-4 w-4" />
                  )}
                  Test Connection
                </button>
              </div>

              {/* Status */}
              {error && (
                <div className="mt-4 p-4 bg-red-50 rounded-md">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}