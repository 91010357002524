import React, { useEffect, useState } from 'react';
import { AlertTriangle, Save } from 'lucide-react';
import { motion } from 'framer-motion';
import { supabase } from '../../lib/supabase';
import { log } from '../../lib/logger';

interface PlatformSettings {
  id: string;
  maintenance_mode: boolean;
  maintenance_message: string;
}

export function MaintenancePage() {
  const [settings, setSettings] = useState<PlatformSettings | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const { data } = await supabase
        .from('platform_settings')
        .select('*')
        .single();

      setSettings(data);
    } catch (error) {
      log('error', 'Failed to fetch platform settings', { error });
    } finally {
      setLoading(false);
    }
  };

  const saveSettings = async () => {
    if (!settings) return;

    setSaving(true);
    try {
      const { error } = await supabase
        .from('platform_settings')
        .update({
          maintenance_mode: settings.maintenance_mode,
          maintenance_message: settings.maintenance_message,
        })
        .eq('id', settings.id);

      if (error) throw error;

      log('info', 'Platform settings updated', {
        maintenance_mode: settings.maintenance_mode,
      });
    } catch (error) {
      log('error', 'Failed to update platform settings', { error });
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
            <AlertTriangle className="h-6 w-6 text-yellow-500" />
            Maintenance Mode
          </h2>
          <p className="mt-2 text-gray-600">
            Enable maintenance mode when you need to perform system updates.
          </p>
        </div>

        <div className="p-6 space-y-6">
          {/* Maintenance Mode Toggle */}
          <div>
            <label className="flex items-center">
              <input
                type="checkbox"
                checked={settings?.maintenance_mode}
                onChange={(e) => setSettings(s => s ? {
                  ...s,
                  maintenance_mode: e.target.checked
                } : null)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2 text-gray-700">Enable Maintenance Mode</span>
            </label>
            {settings?.maintenance_mode && (
              <p className="mt-2 text-sm text-yellow-600">
                Warning: When enabled, only admin users can access the platform.
              </p>
            )}
          </div>

          {/* Maintenance Message */}
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Maintenance Message
            </label>
            <textarea
              value={settings?.maintenance_message}
              onChange={(e) => setSettings(s => s ? {
                ...s,
                maintenance_message: e.target.value
              } : null)}
              rows={4}
              className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              placeholder="Enter the message to display during maintenance..."
            />
          </div>
        </div>

        <div className="px-6 py-4 bg-gray-50 flex justify-end">
          <button
            onClick={saveSettings}
            disabled={saving}
            className="flex items-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Save className="h-4 w-4" />
            {saving ? 'Saving...' : 'Save Settings'}
          </button>
        </div>
      </div>
    </div>
  );
}