import React from 'react';
import { AlertTriangle } from 'lucide-react';
import { motion } from 'framer-motion';

interface AuthFormProps {
  type: 'login' | 'signup';
}

export function AuthForm({ type }: AuthFormProps) {
  return (
    <div className="bg-white shadow-lg rounded-xl px-8 pt-6 pb-8 mb-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="text-center"
      >
        <div className="flex items-center justify-center mb-4">
          <div className="p-3 bg-yellow-100 rounded-full">
            <AlertTriangle className="h-6 w-6 text-yellow-600" />
          </div>
        </div>
        
        <h2 className="text-xl font-bold text-gray-900 mb-4">
          Project No Longer Maintained
        </h2>
        
        <p className="text-gray-600 mb-6">
          Thank you for your interest! However, this project is no longer being maintained and new {type === 'login' ? 'sign-ins' : 'registrations'} are disabled.
        </p>
        
        <div className="bg-yellow-50 p-4 rounded-lg text-sm text-yellow-700">
          This was a fun prototype to demonstrate affiliate marketing capabilities. Check out the source code to learn more about how it was built!
        </div>
      </motion.div>
    </div>
  );
}