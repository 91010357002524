import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Share2, TrendingUp, DollarSign, ExternalLink, Copy, Loader2, Plus } from 'lucide-react';
import { motion } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format, subDays } from 'date-fns';
import { supabase } from '../lib/supabase';
import { log } from '../lib/logger';
import { trackEvent, ANALYTICS_EVENTS } from '../lib/analytics';

interface LinkStats {
  id: string;
  short_code: string;
  external_url: string;
  created_at: string;
  clicks: number;
  earnings: number;
  product_name: string | null;
}

interface DashboardStats {
  totalEarnings: number;
  availableForWithdrawal: number;
  totalClicks: number;
  linkCount: number;
  dailyStats: {
    date: string;
    earnings: number;
    clicks: number;
  }[];
}

const MINIMUM_WITHDRAWAL = 20;

export function Dashboard() {
  const [links, setLinks] = useState<LinkStats[]>([]);
  const [stats, setStats] = useState<DashboardStats>({
    totalEarnings: 0,
    availableForWithdrawal: 0,
    totalClicks: 0,
    linkCount: 0,
    dailyStats: []
  });
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState('7'); // days
  const [withdrawing, setWithdrawing] = useState(false);

  useEffect(() => {
    fetchDashboardData();
  }, [dateRange]);

  const handleWithdrawalRequest = async () => {
    if (stats.availableForWithdrawal < MINIMUM_WITHDRAWAL) {
      return;
    }

    setWithdrawing(true);
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { error } = await supabase
        .from('earnings')
        .update({ status: 'pending_withdrawal' })
        .eq('user_id', user.id)
        .eq('status', 'pending');

      if (error) throw error;

      trackEvent('Earnings', ANALYTICS_EVENTS.EARNINGS.WITHDRAWAL_REQUEST);
      alert('Withdrawal request submitted! Our team will process it within 2-3 business days.');
      
      // Refresh dashboard data
      fetchDashboardData();
    } catch (error) {
      log('error', 'Failed to submit withdrawal request', { error });
      alert('Failed to submit withdrawal request. Please try again.');
    } finally {
      setWithdrawing(false);
    }
  };

  const fetchDashboardData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        log('error', 'No user found when fetching dashboard data');
        return;
      }

      // Get all links
      const { data: linksData, error: linksError } = await supabase
        .from('affiliate_links')
        .select(`
          id,
          short_code,
          external_url,
          created_at
        `)
        .eq('user_id', user.id)
        .order('created_at', { ascending: false });

      if (linksError) throw linksError;

      // Get total clicks for each link
      const clickPromises = linksData.map(async (link) => {
        const { count } = await supabase
          .from('link_clicks')
          .select('*', { count: 'exact', head: true })
          .eq('link_id', link.id);
        return count || 0;
      });

      // Get earnings for each link
      const earningsPromises = linksData.map(async (link) => {
        const { data: earnings } = await supabase
          .from('earnings')
          .select('user_earnings')
          .eq('link_id', link.id);
        return earnings?.reduce((sum, e) => sum + Number(e.user_earnings), 0) || 0;
      });

      // Get daily stats for the selected period
      const startDate = subDays(new Date(), parseInt(dateRange));
      
      // Get daily clicks
      const { data: dailyClicks } = await supabase
        .from('link_clicks')
        .select('created_at')
        .in('link_id', linksData.map(l => l.id))
        .gte('created_at', startDate.toISOString())
        .order('created_at', { ascending: true });

      // Get daily earnings
      const { data: dailyEarnings } = await supabase
        .from('earnings')
        .select('user_earnings, created_at')
        .eq('user_id', user.id)
        .gte('created_at', startDate.toISOString())
        .order('created_at', { ascending: true });

      // Process all data
      const [clickCounts, earningAmounts] = await Promise.all([
        Promise.all(clickPromises),
        Promise.all(earningsPromises)
      ]);

      // Calculate daily stats
      const dailyStats = Array.from({ length: parseInt(dateRange) }, (_, i) => {
        const date = format(subDays(new Date(), i), 'yyyy-MM-dd');
        
        const dayClicks = (dailyClicks || []).filter(c => 
          format(new Date(c.created_at), 'yyyy-MM-dd') === date
        ).length;

        const dayEarnings = (dailyEarnings || [])
          .filter(e => format(new Date(e.created_at), 'yyyy-MM-dd') === date)
          .reduce((sum, e) => sum + Number(e.user_earnings), 0);

        return {
          date,
          clicks: dayClicks,
          earnings: dayEarnings
        };
      }).reverse();

      // Combine link data
      const enrichedLinks = linksData.map((link, i) => ({
        ...link,
        clicks: clickCounts[i],
        earnings: earningAmounts[i],
        product_name: 'Amazon Product'
      }));

      // Get available for withdrawal amount (only pending earnings)
      const { data: pendingEarnings } = await supabase
        .from('earnings')
        .select('user_earnings')
        .eq('user_id', user.id)
        .eq('status', 'pending');

      const availableForWithdrawal = pendingEarnings?.reduce((sum, e) => sum + Number(e.user_earnings), 0) || 0;

      // Update state
      setLinks(enrichedLinks);
      setStats({
        totalEarnings: earningAmounts.reduce((sum, amount) => sum + amount, 0),
        availableForWithdrawal,
        totalClicks: clickCounts.reduce((sum, count) => sum + count, 0),
        linkCount: linksData.length,
        dailyStats
      });

      log('info', 'Dashboard data fetched successfully', {
        userId: user.id,
        linkCount: linksData.length,
        totalClicks: clickCounts.reduce((sum, count) => sum + count, 0),
        totalEarnings: earningAmounts.reduce((sum, amount) => sum + amount, 0),
        availableForWithdrawal
      });
    } catch (error) {
      log('error', 'Failed to fetch dashboard data', { error });
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async (shortCode: string) => {
    const link = `${window.location.origin}/r/${shortCode}`;
    try {
      await navigator.clipboard.writeText(link);
      trackEvent('Links', ANALYTICS_EVENTS.LINKS.SHARE, 'clipboard');
      alert('Link copied to clipboard!');
    } catch (err) {
      log('error', 'Failed to copy link', { error: err, shortCode });
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Stats Overview */}
      <div className="grid grid-cols-1 md:grid-cols-4 gap-6 mb-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-xl shadow-lg p-6"
        >
          <div className="flex items-center gap-4">
            <div className="h-12 w-12 rounded-xl bg-blue-500 flex items-center justify-center">
              <Share2 className="h-6 w-6 text-white" />
            </div>
            <div>
              <p className="text-2xl font-bold text-gray-900">{stats.linkCount}</p>
              <p className="text-gray-600">Active Links</p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white rounded-xl shadow-lg p-6"
        >
          <div className="flex items-center gap-4">
            <div className="h-12 w-12 rounded-xl bg-green-500 flex items-center justify-center">
              <TrendingUp className="h-6 w-6 text-white" />
            </div>
            <div>
              <p className="text-2xl font-bold text-gray-900">{stats.totalClicks}</p>
              <p className="text-gray-600">Total Clicks</p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white rounded-xl shadow-lg p-6"
        >
          <div className="flex items-center gap-4">
            <div className="h-12 w-12 rounded-xl bg-purple-500 flex items-center justify-center">
              <DollarSign className="h-6 w-6 text-white" />
            </div>
            <div>
              <p className="text-2xl font-bold text-gray-900">${stats.totalEarnings.toFixed(2)}</p>
              <p className="text-gray-600">Total Earnings</p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.3 }}
          className="bg-white rounded-xl shadow-lg p-6"
        >
          <div className="flex items-center gap-4">
            <div className="h-12 w-12 rounded-xl bg-indigo-500 flex items-center justify-center">
              <DollarSign className="h-6 w-6 text-white" />
            </div>
            <div>
              <p className="text-2xl font-bold text-gray-900">${stats.availableForWithdrawal.toFixed(2)}</p>
              <p className="text-gray-600">Available to Withdraw</p>
            </div>
          </div>
          <div className="mt-4">
            {stats.availableForWithdrawal >= MINIMUM_WITHDRAWAL ? (
              <button
                onClick={handleWithdrawalRequest}
                disabled={withdrawing}
                className="w-full bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition-colors disabled:opacity-50 flex items-center justify-center gap-2"
              >
                {withdrawing ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Processing...
                  </>
                ) : (
                  'Request Withdrawal'
                )}
              </button>
            ) : (
              <div className="text-sm text-gray-500 bg-gray-50 p-3 rounded-lg">
                Available once you reach ${MINIMUM_WITHDRAWAL} (${(MINIMUM_WITHDRAWAL - stats.availableForWithdrawal).toFixed(2)} more to go)
              </div>
            )}
          </div>
        </motion.div>
      </div>

      {/* Charts */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold text-gray-900">Earnings Trend</h3>
            <select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              className="text-sm border rounded-lg px-3 py-2"
            >
              <option value="7">Last 7 days</option>
              <option value="30">Last 30 days</option>
              <option value="90">Last 90 days</option>
            </select>
          </div>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={stats.dailyStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => format(new Date(date), 'MMM d')}
                />
                <YAxis />
                <Tooltip 
                  formatter={(value) => [`$${Number(value).toFixed(2)}`, 'Earnings']}
                  labelFormatter={(date) => format(new Date(date), 'MMM d, yyyy')}
                />
                <Line
                  type="monotone"
                  dataKey="earnings"
                  stroke="#4F46E5"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-white rounded-xl shadow-lg p-6">
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-lg font-semibold text-gray-900">Click Activity</h3>
            <div className="text-sm text-gray-500">Last {dateRange} days</div>
          </div>
          <div className="h-64">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={stats.dailyStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis 
                  dataKey="date" 
                  tickFormatter={(date) => format(new Date(date), 'MMM d')}
                />
                <YAxis />
                <Tooltip 
                  formatter={(value) => [value, 'Clicks']}
                  labelFormatter={(date) => format(new Date(date), 'MMM d, yyyy')}
                />
                <Line
                  type="monotone"
                  dataKey="clicks"
                  stroke="#10B981"
                  strokeWidth={2}
                  dot={false}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Links Section */}
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-6 border-b">
          <div className="flex items-center justify-between">
            <h2 className="text-xl font-semibold text-gray-900">Your Active Links</h2>
            <Link
              to="/create-link"
              className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-lg transition-colors flex items-center gap-2"
            >
              <Plus className="h-5 w-5" />
              Create New Link
            </Link>
          </div>
        </div>

        <div className="divide-y">
          {links.length === 0 ? (
            <div className="p-12 text-center">
              <div className="max-w-sm mx-auto">
                <Share2 className="h-12 w-12 text-gray-400 mx-auto mb-4" />
                <h3 className="text-lg font-medium text-gray-900 mb-2">
                  Start Sharing & Earning
                </h3>
                <p className="text-gray-500 mb-6">
                  Create your first affiliate link and start earning money by sharing products you love!
                </p>
                <Link
                  to="/create-link"
                  className="inline-flex items-center gap-2 bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600 transition-colors"
                >
                  <Plus className="h-5 w-5" />
                  Create Your First Link
                </Link>
              </div>
            </div>
          ) : (
            links.map((link) => (
              <motion.div
                key={link.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                className="p-6 hover:bg-gray-50 transition-colors"
              >
                <div className="flex items-center gap-6">
                  <div className="flex-1">
                    <h3 className="text-lg font-semibold text-gray-900 mb-1">
                      {link.product_name}
                    </h3>
                    <div className="flex items-center gap-4 text-sm text-gray-500 mb-2">
                      <span>Created {format(new Date(link.created_at), 'MMM d, yyyy')}</span>
                      <span>•</span>
                      <span>{link.clicks} clicks</span>
                      <span>•</span>
                      <span>${link.earnings.toFixed(2)} earned</span>
                    </div>
                    <div className="flex items-center gap-4">
                      <button
                        onClick={() => copyToClipboard(link.short_code)}
                        className="text-blue-500 hover:text-blue-600 flex items-center gap-1"
                      >
                        <Copy className="h-4 w-4" />
                        Copy Link
                      </button>
                      <a
                        href={`/r/${link.short_code}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-500 hover:text-gray-600 flex items-center gap-1"
                      >
                        <ExternalLink className="h-4 w-4" />
                        Preview
                      </a>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))
          )}
        </div>
      </div>
    </div>
  );
}