import { log } from './logger';
import queryString from 'query-string';

interface AmazonUrlComponents {
  asin: string | null;
  marketplace: string;
  variationSelections?: Record<string, string>;
  isLightningDeal?: boolean;
  dealId?: string;
}

const VALID_ASIN_PATTERN = /^[A-Z0-9]{10}$/;
const AMAZON_DOMAINS = [
  'amazon.com', 'amazon.co.uk', 'amazon.de', 'amazon.fr', 'amazon.it', 
  'amazon.es', 'amazon.ca', 'amazon.com.au', 'amazon.co.jp', 'amazon.in',
  'a.co' // Add support for Amazon short domain
];

/**
 * Extract ASIN and other components from any Amazon URL format
 */
export function parseAmazonUrl(url: string): AmazonUrlComponents | null {
  try {
    log('info', 'Parsing Amazon URL', { url });
    
    // Clean and normalize URL
    url = url.trim().toLowerCase();
    
    // Handle amzn.to short URLs
    if (url.includes('amzn.to')) {
      log('info', 'Detected amzn.to short URL', { url });
      // Note: We'll need to follow the redirect to get the actual URL
      // This should be handled server-side for security
      return null;
    }

    // Parse URL
    let urlObj: URL;
    try {
      urlObj = new URL(url);
    } catch {
      log('warn', 'Invalid URL format', { url });
      return null;
    }

    // Verify Amazon domain
    const domain = urlObj.hostname.replace('www.', '');
    if (!AMAZON_DOMAINS.includes(domain)) {
      log('warn', 'Not an Amazon domain', { domain });
      return null;
    }

    // Handle a.co short URLs
    if (domain === 'a.co') {
      const shortPath = urlObj.pathname.split('/').filter(Boolean);
      if (shortPath.length >= 2 && shortPath[0] === 'd') {
        // The format is a.co/d/{ASIN}
        const potentialAsin = shortPath[1].toUpperCase();
        if (VALID_ASIN_PATTERN.test(potentialAsin)) {
          log('info', 'Successfully parsed a.co short URL', { asin: potentialAsin });
          return {
            asin: potentialAsin,
            marketplace: 'amazon.com' // Short URLs are typically for amazon.com
          };
        }
      }
    }

    // Extract ASIN using various patterns
    let asin: string | null = null;
    const patterns = [
      // Standard product page
      { regex: /\/dp\/([A-Z0-9]{10})/i, type: 'dp' },
      // Alternative product page
      { regex: /\/gp\/product\/([A-Z0-9]{10})/i, type: 'gp/product' },
      // Legacy format
      { regex: /\/exec\/obidos\/asin\/([A-Z0-9]{10})/i, type: 'legacy' },
      // Legacy detail page
      { regex: /\/exec\/obidos\/tg\/detail\/-\/([A-Z0-9]{10})/i, type: 'legacy-detail' },
      // Direct ASIN in URL
      { regex: /\/([A-Z0-9]{10})(?:\/|\?|$)/i, type: 'direct' }
    ];

    for (const pattern of patterns) {
      const match = urlObj.pathname.match(pattern.regex);
      if (match) {
        asin = match[1].toUpperCase();
        log('info', `ASIN extracted using ${pattern.type} pattern`, { asin });
        break;
      }
    }

    // Check query parameters if no ASIN found
    if (!asin) {
      const params = queryString.parse(urlObj.search);
      if (params.asin) {
        asin = (params.asin as string).toUpperCase();
        log('info', 'ASIN extracted from query params', { asin });
      }
    }

    // Validate ASIN format
    if (asin && !VALID_ASIN_PATTERN.test(asin)) {
      log('warn', 'Invalid ASIN format', { asin });
      return null;
    }

    // Extract variation selections
    const variationSelections: Record<string, string> = {};
    const params = queryString.parse(urlObj.search);
    for (const [key, value] of Object.entries(params)) {
      if (key.startsWith('var_') || key === 'psc') {
        variationSelections[key] = value as string;
      }
    }

    // Check for Lightning Deal
    const isLightningDeal = urlObj.pathname.includes('/deal/') || params.deal;
    const dealId = params.deal as string;

    return {
      asin,
      marketplace: domain === 'a.co' ? 'amazon.com' : domain,
      ...(Object.keys(variationSelections).length > 0 && { variationSelections }),
      ...(isLightningDeal && { isLightningDeal, dealId })
    };
  } catch (error) {
    log('error', 'Failed to parse Amazon URL', { error, url });
    return null;
  }
}

export function generateAmazonAffiliateUrl(
  url: string,
  options: {
    shortId: string;
    shortCode: string;
    marketplace?: string;
  }
): string {
  try {
    // Parse the URL
    const urlObj = new URL(url);
    
    // Add affiliate tag and tracking parameters
    const params = {
      tag: 'easyearn-20',
      linkId: options.shortCode,
      utm_source: 'easyearn',
      utm_medium: detectPlatform(),
      utm_campaign: options.shortCode,
      utm_content: options.shortId
    };

    // Add params to URL
    Object.entries(params).forEach(([key, value]) => {
      urlObj.searchParams.set(key, value);
    });

    // Preserve any existing query parameters that aren't being overwritten
    const existingParams = queryString.parse(urlObj.search);
    Object.entries(existingParams).forEach(([key, value]) => {
      if (!params.hasOwnProperty(key)) {
        urlObj.searchParams.set(key, value as string);
      }
    });

    log('info', 'Generated affiliate URL', { originalUrl: url, affiliateUrl: urlObj.toString() });
    return urlObj.toString();
  } catch (error) {
    log('error', 'Failed to generate affiliate URL', { error, url });
    throw error;
  }
}

function detectPlatform(): string {
  const ua = navigator.userAgent.toLowerCase();
  const referrer = document.referrer.toLowerCase();

  if (ua.includes('fban') || ua.includes('fbav') || referrer.includes('facebook.com')) {
    return 'facebook';
  }
  if (ua.includes('instagram') || referrer.includes('instagram.com')) {
    return 'instagram';
  }
  if (referrer.includes('tiktok.com')) {
    return 'tiktok';
  }
  if (referrer.includes('twitter.com') || referrer.includes('x.com')) {
    return 'twitter';
  }
  if (referrer.includes('pinterest.com')) {
    return 'pinterest';
  }
  if (ua.includes('wv')) {
    return 'webview';
  }

  return 'web';
}