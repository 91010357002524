import React, { useEffect, useState } from 'react';
import { Bell, DollarSign, Settings, Save } from 'lucide-react';
import { motion } from 'framer-motion';
import { supabase } from '../lib/supabase';

interface NotificationSettings {
  id: string;
  new_user_notifications: boolean;
  new_link_notifications: boolean;
  sale_notifications: boolean;
  withdrawal_notifications: boolean;
  feedback_notifications: boolean;
  sale_threshold: number;
  notifications_paused: boolean;
}

export function AdminSettings() {
  const [settings, setSettings] = useState<NotificationSettings | null>(null);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data } = await supabase
        .from('notification_settings')
        .select('*')
        .eq('user_id', user.id)
        .single();

      if (data) {
        setSettings(data);
      } else {
        // Create default settings if none exist
        const { data: newSettings } = await supabase
          .from('notification_settings')
          .insert({
            user_id: user.id,
            new_user_notifications: true,
            new_link_notifications: true,
            sale_notifications: true,
            withdrawal_notifications: true,
            feedback_notifications: true,
            sale_threshold: 0,
            notifications_paused: false
          })
          .select()
          .single();

        setSettings(newSettings);
      }
    } catch (error) {
      console.error('Error fetching settings:', error);
    } finally {
      setLoading(false);
    }
  };

  const saveSettings = async () => {
    if (!settings) return;

    setSaving(true);
    try {
      const { error } = await supabase
        .from('notification_settings')
        .update(settings)
        .eq('id', settings.id);

      if (error) throw error;
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
            <Settings className="h-6 w-6 text-blue-500" />
            Admin Settings
          </h2>
        </div>

        <div className="p-6 space-y-6">
          {/* Notification Controls */}
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center gap-2">
              <Bell className="h-5 w-5 text-blue-500" />
              Email Notifications
            </h3>

            <div className="space-y-4">
              <label className="flex items-center justify-between">
                <span className="text-gray-700">Pause All Notifications</span>
                <input
                  type="checkbox"
                  checked={settings?.notifications_paused}
                  onChange={(e) => setSettings(s => s ? {
                    ...s,
                    notifications_paused: e.target.checked
                  } : null)}
                  className="form-checkbox h-5 w-5 text-blue-600"
                />
              </label>

              <div className="grid gap-4">
                {[
                  { key: 'new_user_notifications', label: 'New User Registrations' },
                  { key: 'new_link_notifications', label: 'New Affiliate Links' },
                  { key: 'sale_notifications', label: 'Sales' },
                  { key: 'withdrawal_notifications', label: 'Withdrawal Requests' },
                  { key: 'feedback_notifications', label: 'User Feedback' }
                ].map(({ key, label }) => (
                  <label key={key} className="flex items-center justify-between">
                    <span className="text-gray-700">{label}</span>
                    <input
                      type="checkbox"
                      checked={settings?.[key as keyof NotificationSettings] as boolean}
                      onChange={(e) => setSettings(s => s ? {
                        ...s,
                        [key]: e.target.checked
                      } : null)}
                      disabled={settings?.notifications_paused}
                      className="form-checkbox h-5 w-5 text-blue-600"
                    />
                  </label>
                ))}
              </div>
            </div>
          </div>

          {/* Thresholds */}
          <div>
            <h3 className="text-lg font-medium text-gray-900 mb-4 flex items-center gap-2">
              <DollarSign className="h-5 w-5 text-blue-500" />
              Notification Thresholds
            </h3>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Minimum Sale Amount for Notifications
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="number"
                    min="0"
                    step="0.01"
                    value={settings?.sale_threshold}
                    onChange={(e) => setSettings(s => s ? {
                      ...s,
                      sale_threshold: parseFloat(e.target.value)
                    } : null)}
                    disabled={settings?.notifications_paused}
                    className="focus:ring-blue-500 focus:border-blue-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="px-6 py-4 bg-gray-50 flex justify-end">
          <button
            onClick={saveSettings}
            disabled={saving}
            className="flex items-center gap-2 px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            <Save className="h-4 w-4" />
            {saving ? 'Saving...' : 'Save Settings'}
          </button>
        </div>
      </div>
    </div>
  );
}