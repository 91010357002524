import React, { useState, useEffect } from 'react';
import { Share2, Copy, Loader2, ExternalLink, AlertCircle } from 'lucide-react';
import { motion } from 'framer-motion';
import { generateAffiliateLink } from '../lib/links';
import { supabase, initializeSupabase } from '../lib/supabase';
import { log } from '../lib/logger';
import { trackEvent, ANALYTICS_EVENTS } from '../lib/analytics';

export function CreateLinkPage() {
  const [amazonUrl, setAmazonUrl] = useState('');
  const [urlError, setUrlError] = useState<string | null>(null);
  const [generating, setGenerating] = useState(false);
  const [generatedLink, setGeneratedLink] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    const init = async () => {
      try {
        // Initialize Supabase first
        const success = await initializeSupabase();
        if (!success) {
          throw new Error('Failed to initialize Supabase');
        }

        // Then check auth
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) throw error;
        if (user) {
          setUserId(user.id);
        }
      } catch (error) {
        log('error', 'Failed to initialize page', { error });
      } finally {
        setInitialized(true);
      }
    };
    init();
  }, []);

  const handleUrlSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setUrlError(null);
    setGenerating(true);

    try {
      if (!userId) {
        throw new Error('Please sign in to create links');
      }

      // Generate affiliate link
      const result = await generateAffiliateLink({
        url: amazonUrl,
        userId
      });

      if (!result.success || !result.data) {
        throw new Error(result.error || 'Failed to generate link');
      }

      // Set generated link
      setGeneratedLink(result.data.shareableLink);
      
      // Clear input
      setAmazonUrl('');

      // Track success
      trackEvent('Links', ANALYTICS_EVENTS.LINKS.CREATE);
    } catch (error) {
      const message = error instanceof Error ? error.message : 'Failed to generate link';
      setUrlError(message);
      log('error', 'Link generation failed in UI', { error, url: amazonUrl });
    } finally {
      setGenerating(false);
    }
  };

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
      trackEvent('Links', ANALYTICS_EVENTS.LINKS.SHARE, 'clipboard');
      alert('Link copied to clipboard!');
    } catch (err) {
      log('error', 'Failed to copy to clipboard', { error: err });
    }
  };

  if (!initialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Instructions */}
      <div className="bg-white rounded-xl shadow-lg overflow-hidden mb-8">
        <div className="p-6">
          <h2 className="text-xl font-bold text-gray-900 mb-4">
            Create Your Affiliate Link
          </h2>
          <div className="space-y-4">
            <div className="flex items-start gap-3">
              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-blue-100 text-blue-600 font-medium">
                1
              </span>
              <div>
                <h3 className="font-medium text-gray-900">Find a product</h3>
                <p className="text-gray-600">
                  Go to <a href="https://amazon.com" target="_blank" rel="noopener noreferrer" className="text-blue-600 hover:underline">Amazon.com</a> and find a product you want to share
                </p>
              </div>
            </div>
            <div className="flex items-start gap-3">
              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-blue-100 text-blue-600 font-medium">
                2
              </span>
              <div>
                <h3 className="font-medium text-gray-900">Copy the URL</h3>
                <p className="text-gray-600">
                  Copy the product page URL from your browser's address bar
                </p>
              </div>
            </div>
            <div className="flex items-start gap-3">
              <span className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-blue-100 text-blue-600 font-medium">
                3
              </span>
              <div>
                <h3 className="font-medium text-gray-900">Generate your link</h3>
                <p className="text-gray-600">
                  Paste the URL below and click "Generate Link"
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* URL Input */}
      <div className="bg-white rounded-xl shadow-lg overflow-hidden">
        <div className="p-6">
          <form onSubmit={handleUrlSubmit}>
            <label className="block text-sm font-medium text-gray-700 mb-2">
              Amazon Product URL
            </label>
            <div className="flex gap-4">
              <input
                type="url"
                value={amazonUrl}
                onChange={(e) => setAmazonUrl(e.target.value)}
                placeholder="https://www.amazon.com/dp/..."
                className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                required
              />
              <button
                type="submit"
                disabled={generating || !amazonUrl || !userId}
                className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              >
                {generating ? (
                  <>
                    <Loader2 className="h-5 w-5 animate-spin" />
                    Generating...
                  </>
                ) : (
                  <>
                    <Share2 className="h-5 w-5" />
                    Generate Link
                  </>
                )}
              </button>
            </div>
            {urlError && (
              <div className="mt-2 text-red-500 flex items-center gap-1">
                <AlertCircle className="h-4 w-4" />
                {urlError}
              </div>
            )}
          </form>
        </div>
      </div>

      {/* Generated Link Modal */}
      {generatedLink && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center p-4 z-50">
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            className="bg-white rounded-xl p-6 max-w-md w-full"
          >
            <h3 className="text-xl font-bold text-gray-900 mb-4">Your Link is Ready! 🎉</h3>
            <div className="bg-gray-50 rounded-lg p-4 mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-2">
                Your Affiliate Link:
              </label>
              <div className="flex items-center gap-2">
                <input
                  type="text"
                  value={generatedLink}
                  readOnly
                  className="flex-1 px-3 py-2 border rounded-lg bg-white"
                />
                <button
                  onClick={() => copyToClipboard(generatedLink)}
                  className="p-2 text-blue-500 hover:text-blue-600"
                >
                  <Copy className="h-5 w-5" />
                </button>
              </div>
            </div>
            <div className="flex items-center justify-end gap-2">
              <button
                onClick={() => setGeneratedLink(null)}
                className="px-4 py-2 text-gray-600 hover:text-gray-900"
              >
                Close
              </button>
              <a
                href={generatedLink}
                target="_blank"
                rel="noopener noreferrer"
                className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors flex items-center gap-2"
              >
                <ExternalLink className="h-5 w-5" />
                Preview Link
              </a>
            </div>
          </motion.div>
        </div>
      )}
    </div>
  );
}