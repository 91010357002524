import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Loader2, AlertCircle } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { log } from '../lib/logger';
import { trackEvent, ANALYTICS_EVENTS } from '../lib/analytics';

export function RedirectPage() {
  const { shortCode } = useParams();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleRedirect = async () => {
      if (!shortCode) {
        setError('Invalid link');
        return;
      }

      try {
        // Get the affiliate link
        const { data: link, error: linkError } = await supabase
          .from('affiliate_links')
          .select('id, external_url, user_id')
          .eq('short_code', shortCode)
          .single();

        if (linkError || !link) {
          throw new Error('Link not found');
        }

        // Track the click without waiting for response
        const platform = detectPlatform();
        supabase
          .from('link_clicks')
          .insert({
            link_id: link.id,
            platform,
            user_agent: navigator.userAgent,
            referrer: document.referrer,
            context: {
              url: window.location.href,
              timestamp: new Date().toISOString(),
              viewport: {
                width: window.innerWidth,
                height: window.innerHeight
              }
            }
          })
          .then(() => {
            trackEvent('Links', ANALYTICS_EVENTS.LINKS.CLICK, platform);
          })
          .catch(err => {
            log('error', 'Failed to track click', { error: err });
          });

        // Generate final URL with tracking parameters
        const url = new URL(link.external_url);
        url.searchParams.append('tag', 'easyearn-20');
        url.searchParams.append('utm_source', 'easyearn');
        url.searchParams.append('utm_medium', platform);
        url.searchParams.append('utm_campaign', shortCode);
        url.searchParams.append('linkId', shortCode);

        // Log successful redirect
        log('info', 'Redirecting to external URL', {
          shortCode,
          linkId: link.id,
          userId: link.user_id,
          url: url.toString()
        });

        // Redirect to the external URL
        window.location.href = url.toString();
      } catch (error) {
        const message = error instanceof Error ? error.message : 'Redirect failed';
        log('error', 'Redirect failed', { error, shortCode });
        setError(message);
      }
    };

    handleRedirect();
  }, [shortCode]);

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6 text-center">
          <div className="flex items-center justify-center gap-2 text-red-600 mb-4">
            <AlertCircle className="h-6 w-6" />
            <h1 className="text-xl font-semibold">Link Not Found</h1>
          </div>
          <p className="text-gray-600 mb-4">{error}</p>
          <a href="/" className="text-blue-600 hover:text-blue-700">
            Return Home
          </a>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center">
      <div className="text-center">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500 mx-auto mb-4" />
        <p className="text-gray-600">Redirecting to product...</p>
      </div>
    </div>
  );
}

function detectPlatform(): string {
  const ua = navigator.userAgent.toLowerCase();
  const referrer = document.referrer.toLowerCase();

  if (ua.includes('fban') || ua.includes('fbav') || referrer.includes('facebook.com')) {
    return 'facebook';
  }
  if (ua.includes('instagram') || referrer.includes('instagram.com')) {
    return 'instagram';
  }
  if (referrer.includes('tiktok.com')) {
    return 'tiktok';
  }
  if (referrer.includes('twitter.com') || referrer.includes('x.com')) {
    return 'twitter';
  }
  if (referrer.includes('pinterest.com')) {
    return 'pinterest';
  }
  if (ua.includes('wv')) {
    return 'webview';
  }

  return 'web';
}