import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { Header } from './components/layout/Header';
import { Footer } from './components/layout/Footer';
import { LandingPage } from './pages/LandingPage';
import { AuthPage } from './pages/AuthPage';
import { SignOutPage } from './pages/SignOutPage';
import { Dashboard } from './pages/Dashboard';
import { AdminDashboard } from './pages/AdminDashboard';
import { AdminSettings } from './pages/AdminSettings';
import { IntegrationsPage } from './pages/admin/IntegrationsPage';
import { MaintenancePage } from './pages/admin/MaintenancePage';
import { WithdrawalsPage } from './pages/admin/WithdrawalsPage';
import { ReportPage } from './pages/admin/ReportPage';
import { CreateLinkPage } from './pages/CreateLinkPage';
import { AdminSetupPage } from './pages/AdminSetupPage';
import { RedirectPage } from './pages/RedirectPage';
import { ErrorBoundary } from './lib/ErrorBoundary';
import { supabase, initializeSupabase } from './lib/supabase';
import { log } from './lib/logger';
import { trackPageView } from './lib/analytics';

// Analytics tracker component
function PageTracker() {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return null;
}

function MaintenanceMode({ children }: { children: React.ReactNode }) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [inMaintenance, setInMaintenance] = useState(false);
  const [maintenanceMessage, setMaintenanceMessage] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkMaintenanceAndAdmin();
  }, []);

  const checkMaintenanceAndAdmin = async () => {
    try {
      // Fetch platform settings
      const { data: settings } = await supabase
        .from('platform_settings')
        .select('maintenance_mode, maintenance_message')
        .limit(1)
        .single();

      setInMaintenance(settings?.maintenance_mode || false);
      setMaintenanceMessage(settings?.maintenance_message || '');

      // Check if user is admin
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data: userData } = await supabase
          .from('users')
          .select('is_admin')
          .eq('id', user.id)
          .single();
        setIsAdmin(userData?.is_admin || false);
      }
    } catch (error) {
      log('error', 'Failed to check maintenance status', { error });
      setInMaintenance(false);
      setMaintenanceMessage('');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
    </div>;
  }

  if (inMaintenance && !isAdmin) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6 text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">🚧 Under Maintenance</h1>
          <p className="text-gray-600">{maintenanceMessage}</p>
        </div>
      </div>
    );
  }

  return <>{children}</>;
}

function PrivateRoute({ children, adminOnly = false }: { children: React.ReactNode, adminOnly?: boolean }) {
  const [user, setUser] = useState<any>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    try {
      // First ensure Supabase is initialized
      const initialized = await initializeSupabase();
      if (!initialized) {
        throw new Error('Failed to initialize Supabase');
      }

      const { data: { user }, error: authError } = await supabase.auth.getUser();
      if (authError) throw authError;
      
      if (!user) {
        setLoading(false);
        return;
      }

      const { data, error: userError } = await supabase
        .from('users')
        .select('is_admin')
        .eq('id', user.id)
        .single();

      if (userError) throw userError;

      setUser(user);
      setIsAdmin(data?.is_admin || false);
    } catch (error) {
      log('error', 'Failed to check authentication', { error });
      setError(error instanceof Error ? error.message : 'Authentication failed');
      setUser(null);
      setIsAdmin(false);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
    </div>;
  }

  if (error) {
    return <div className="min-h-screen flex items-center justify-center p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6 text-center">
        <h1 className="text-xl font-bold text-red-600 mb-2">Authentication Error</h1>
        <p className="text-gray-600 mb-4">{error}</p>
        <a href="/login" className="text-blue-600 hover:text-blue-700">
          Return to Login
        </a>
      </div>
    </div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  if (adminOnly && !isAdmin) {
    return <Navigate to="/dashboard" />;
  }

  return <>{children}</>;
}

function App() {
  const [initialized, setInitialized] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const init = async () => {
      try {
        const success = await initializeSupabase();
        if (!success) {
          throw new Error('Failed to initialize application');
        }
        setInitialized(true);
      } catch (err) {
        log('error', 'Failed to initialize app', { error: err });
        setError(err instanceof Error ? err.message : 'Failed to initialize application');
        setInitialized(true); // Set initialized to show error state
      }
    };
    init();
  }, []);

  if (!initialized) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center p-4">
        <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6 text-center">
          <h1 className="text-xl font-bold text-red-600 mb-2">Application Error</h1>
          <p className="text-gray-600 mb-4">{error}</p>
          <button 
            onClick={() => window.location.reload()} 
            className="text-blue-600 hover:text-blue-700"
          >
            Retry
          </button>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <ErrorBoundary>
        <PageTracker />
        <MaintenanceMode>
          <div className="min-h-screen bg-gray-50 flex flex-col">
            <Header />
            <main className="flex-1">
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/login" element={<AuthPage type="login" />} />
                <Route path="/signup" element={<AuthPage type="signup" />} />
                <Route path="/signout" element={<SignOutPage />} />
                <Route path="/admin-setup" element={<AdminSetupPage />} />
                <Route path="/dashboard" element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                } />
                <Route path="/admin" element={
                  <PrivateRoute adminOnly>
                    <AdminDashboard />
                  </PrivateRoute>
                } />
                <Route path="/admin/settings" element={
                  <PrivateRoute adminOnly>
                    <AdminSettings />
                  </PrivateRoute>
                } />
                <Route path="/admin/integrations" element={
                  <PrivateRoute adminOnly>
                    <IntegrationsPage />
                  </PrivateRoute>
                } />
                <Route path="/admin/maintenance" element={
                  <PrivateRoute adminOnly>
                    <MaintenancePage />
                  </PrivateRoute>
                } />
                <Route path="/admin/withdrawals" element={
                  <PrivateRoute adminOnly>
                    <WithdrawalsPage />
                  </PrivateRoute>
                } />
                <Route path="/admin/report" element={
                  <PrivateRoute adminOnly>
                    <ReportPage />
                  </PrivateRoute>
                } />
                <Route path="/create-link" element={
                  <PrivateRoute>
                    <CreateLinkPage />
                  </PrivateRoute>
                } />
                <Route path="/r/:shortCode" element={
                  <ErrorBoundary>
                    <RedirectPage />
                  </ErrorBoundary>
                } />
              </Routes>
            </main>
            <Footer />
          </div>
        </MaintenanceMode>
      </ErrorBoundary>
    </Router>
  );
}

export default App;