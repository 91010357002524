import { supabase } from './supabase';
import { log } from './logger';
import { parseAmazonUrl } from './amazon';
import { trackEvent, ANALYTICS_EVENTS } from './analytics';

interface GenerateLinkParams {
  url: string;
  userId: string;
}

export async function generateAffiliateLink({ url, userId }: GenerateLinkParams) {
  try {
    log('info', 'Starting affiliate link generation', { url, userId });

    // First check if user exists and is verified
    const { data: user, error: userError } = await supabase
      .from('users')
      .select('id, email_verified')
      .eq('id', userId)
      .single();

    if (userError || !user) {
      log('error', 'User not found or not verified', { error: userError, userId });
      throw new Error('User not found or not verified');
    }

    // Validate the URL
    const urlComponents = parseAmazonUrl(url);
    if (!urlComponents) {
      throw new Error('Invalid Amazon product URL');
    }

    log('info', 'URL parsed successfully', urlComponents);

    // Generate short code
    const shortCode = generateUniqueCode();

    // Create affiliate link record
    const { data: link, error: linkError } = await supabase
      .from('affiliate_links')
      .insert({
        user_id: userId,
        short_code: shortCode,
        external_url: url,
        created_at: new Date().toISOString()
      })
      .select()
      .single();

    if (linkError) {
      log('error', 'Failed to create affiliate link', { error: linkError });
      throw new Error('Failed to generate link');
    }

    if (!link) {
      throw new Error('No link returned from database');
    }

    // Track event
    trackEvent('Links', ANALYTICS_EVENTS.LINKS.CREATE);

    // Log success
    log('info', 'Affiliate link created successfully', {
      shortCode,
      userId,
      linkId: link.id
    });

    return {
      success: true,
      data: {
        shortCode,
        shareableLink: `${window.location.origin}/r/${shortCode}`,
      }
    };
  } catch (error) {
    log('error', 'Link generation failed', { error, url });
    return {
      success: false,
      error: error instanceof Error ? error.message : 'Failed to generate link'
    };
  }
}

function generateUniqueCode(): string {
  const chars = 'abcdefghijkmnpqrstuvwxyz23456789'; // Omit confusing characters
  let code = '';
  for (let i = 0; i < 6; i++) {
    code += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return code;
}