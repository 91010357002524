import React, { useState, useEffect } from 'react';
import { DollarSign, CheckCircle, XCircle, AlertTriangle } from 'lucide-react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';
import { supabase } from '../../lib/supabase';
import { log } from '../../lib/logger';

interface WithdrawalRequest {
  id: string;
  user_id: string;
  amount: number;
  status: 'pending' | 'approved' | 'rejected';
  created_at: string;
  user: {
    email: string;
    display_name: string;
  };
}

export function WithdrawalsPage() {
  const [withdrawals, setWithdrawals] = useState<WithdrawalRequest[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchWithdrawals();
  }, []);

  const fetchWithdrawals = async () => {
    try {
      const { data, error } = await supabase
        .from('earnings')
        .select(`
          id,
          user_id,
          amount,
          status,
          created_at,
          user:users (
            email,
            display_name
          )
        `)
        .eq('status', 'pending')
        .gte('amount', 20)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setWithdrawals(data || []);
    } catch (error) {
      log('error', 'Failed to fetch withdrawals', { error });
    } finally {
      setLoading(false);
    }
  };

  const handleWithdrawal = async (id: string, action: 'approve' | 'reject') => {
    try {
      const { error } = await supabase
        .from('earnings')
        .update({ 
          status: action === 'approve' ? 'paid' : 'rejected',
          payout_date: action === 'approve' ? new Date().toISOString() : null
        })
        .eq('id', id);

      if (error) throw error;

      // Refresh the list
      fetchWithdrawals();

      // Log the action
      log('info', `Withdrawal ${action}d`, { withdrawalId: id });
    } catch (error) {
      log('error', `Failed to ${action} withdrawal`, { error });
      alert(`Failed to ${action} withdrawal. Please try again.`);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden">
        <div className="p-6 border-b border-gray-200">
          <h2 className="text-2xl font-bold text-gray-900 flex items-center gap-2">
            <DollarSign className="h-6 w-6 text-blue-500" />
            Withdrawal Requests
          </h2>
          <p className="mt-2 text-gray-600">
            Review and process user withdrawal requests
          </p>
        </div>

        {withdrawals.length === 0 ? (
          <div className="p-6 text-center text-gray-500">
            No pending withdrawal requests
          </div>
        ) : (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    User
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Amount
                  </th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Requested
                  </th>
                  <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {withdrawals.map((withdrawal) => (
                  <motion.tr
                    key={withdrawal.id}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {withdrawal.user.display_name || withdrawal.user.email}
                        </div>
                        <div className="text-sm text-gray-500">
                          {withdrawal.user.email}
                        </div>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm font-medium text-gray-900">
                        ${withdrawal.amount.toFixed(2)}
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {format(new Date(withdrawal.created_at), 'MMM d, yyyy h:mm a')}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex items-center justify-end gap-2">
                        <button
                          onClick={() => handleWithdrawal(withdrawal.id, 'approve')}
                          className="text-green-600 hover:text-green-900"
                        >
                          <CheckCircle className="h-5 w-5" />
                        </button>
                        <button
                          onClick={() => handleWithdrawal(withdrawal.id, 'reject')}
                          className="text-red-600 hover:text-red-900"
                        >
                          <XCircle className="h-5 w-5" />
                        </button>
                      </div>
                    </td>
                  </motion.tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
}