import React from 'react';
import { Link } from 'react-router-dom';
import { AuthForm } from '../components/auth/AuthForm';

interface AuthPageProps {
  type: 'login' | 'signup';
}

export function AuthPage({ type }: AuthPageProps) {
  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {type === 'login' ? 'Welcome back!' : 'Create your account'}
        </h2>
        <p className="mt-2 text-center text-gray-600">
          <Link to="/" className="text-blue-600 hover:text-blue-500">
            Return to Home
          </Link>
        </p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <AuthForm type={type} />
      </div>
    </div>
  );
}