import React, { useEffect, useState } from 'react';
import { DollarSign, Users, TrendingUp, Download, Search, Calendar, Filter } from 'lucide-react';
import { motion } from 'framer-motion';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format, subDays } from 'date-fns';
import { supabase } from '../lib/supabase';
import { log } from '../lib/logger';

interface AdminStats {
  totalUsers: number;
  activeUsers: number;
  totalRevenue: number;
  conversionRate: number;
  recentActivity: {
    type: 'signup' | 'earnings';
    user: {
      email: string;
      display_name: string | null;
    };
    amount?: number;
    timestamp: string;
  }[];
}

interface UserData {
  id: string;
  email: string;
  display_name: string | null;
  created_at: string;
  last_active: string;
  total_earnings: number;
  links_created: number;
}

export function AdminDashboard() {
  const [stats, setStats] = useState<AdminStats>({
    totalUsers: 0,
    activeUsers: 0,
    totalRevenue: 0,
    conversionRate: 0,
    recentActivity: []
  });
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [dateRange, setDateRange] = useState('7'); // days
  const [searchQuery, setSearchQuery] = useState('');
  const [dailyStats, setDailyStats] = useState<any[]>([]);

  useEffect(() => {
    checkAdminAndFetchData();
  }, [dateRange]);

  const checkAdminAndFetchData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        throw new Error('Not authenticated');
      }

      const { data: userData, error: userError } = await supabase
        .from('users')
        .select('is_admin')
        .eq('id', user.id)
        .single();

      if (userError) throw userError;
      if (!userData?.is_admin) throw new Error('Not authorized');

      await fetchAdminData();
    } catch (error) {
      log('error', 'Admin verification failed', { error });
      setError(error instanceof Error ? error.message : 'Access denied');
      setLoading(false);
    }
  };

  const fetchAdminData = async () => {
    try {
      const startDate = subDays(new Date(), parseInt(dateRange));

      // Get all users with their stats
      const { data: usersData, error: usersError } = await supabase
        .from('users')
        .select(`
          id,
          email,
          display_name,
          created_at,
          affiliate_links (count),
          earnings!earnings_user_id_fkey (
            amount,
            status
          )
        `);

      if (usersError) throw usersError;

      // Process user data
      const processedUsers: UserData[] = usersData.map(user => {
        const totalEarnings = user.earnings?.reduce((sum: number, e: any) => 
          sum + Number(e.amount), 0) || 0;
        
        const totalWithdrawn = user.earnings?.reduce((sum: number, e: any) => 
          e.status === 'paid' ? sum + Number(e.amount) : sum, 0) || 0;

        return {
          id: user.id,
          email: user.email || 'Unknown Email',
          display_name: user.display_name,
          created_at: user.created_at,
          last_active: user.last_active,
          total_earnings: totalEarnings,
          links_created: user.affiliate_links?.count || 0
        };
      });

      // Get recent activity
      const recentActivity = usersData
        .filter(user => user.email) // Only include users with email
        .map(user => ({
          type: 'signup' as const,
          user: {
            email: user.email || 'Unknown Email',
            display_name: user.display_name
          },
          timestamp: user.created_at
        }))
        .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
        .slice(0, 10);

      // Calculate stats
      const stats: AdminStats = {
        totalUsers: processedUsers.length,
        activeUsers: processedUsers.filter(u => u.links_created > 0).length,
        totalRevenue: processedUsers.reduce((sum, user) => sum + user.total_earnings, 0),
        conversionRate: processedUsers.length > 0 
          ? (processedUsers.filter(u => u.links_created > 0).length / processedUsers.length) * 100 
          : 0,
        recentActivity
      };

      setUsers(processedUsers);
      setStats(stats);
      
      log('info', 'Admin dashboard data fetched successfully', {
        totalUsers: processedUsers.length,
        activeUsers: stats.activeUsers
      });
    } catch (error) {
      log('error', 'Failed to fetch admin data', { error });
    } finally {
      setLoading(false);
    }
  };

  const exportUserData = () => {
    const csvData = users.map(user => ({
      Email: user.email,
      Name: user.display_name || 'Not Set',
      'Signup Date': format(new Date(user.created_at), 'yyyy-MM-dd'),
      'Last Active': user.last_active ? format(new Date(user.last_active), 'yyyy-MM-dd') : 'Never',
      'Total Earnings': user.total_earnings.toFixed(2),
      'Links Created': user.links_created
    }));

    const csvString = [
      Object.keys(csvData[0]).join(','),
      ...csvData.map(row => Object.values(row).join(','))
    ].join('\n');

    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `easyearn-users-${format(new Date(), 'yyyy-MM-dd')}.csv`;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const filteredUsers = users.filter(user =>
    user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (user.display_name?.toLowerCase() || '').includes(searchQuery.toLowerCase())
  );

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-red-500 text-center">
          <h2 className="text-2xl font-bold mb-2">Access Denied</h2>
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Controls */}
      <div className="flex items-center justify-between mb-8">
        <div className="flex items-center gap-4">
          <select
            value={dateRange}
            onChange={(e) => setDateRange(e.target.value)}
            className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="7">Last 7 days</option>
            <option value="30">Last 30 days</option>
            <option value="90">Last 90 days</option>
          </select>
          <button
            onClick={exportUserData}
            className="flex items-center gap-2 px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
          >
            <Download className="h-5 w-5" />
            Export Users
          </button>
        </div>
      </div>

      {/* Key Metrics */}
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4 mb-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-white rounded-lg shadow p-6"
        >
          <div className="flex items-center">
            <div className="p-3 rounded-lg bg-blue-100">
              <Users className="h-6 w-6 text-blue-600" />
            </div>
            <div className="ml-4">
              <h2 className="text-sm font-medium text-gray-500">Total Users</h2>
              <p className="text-2xl font-semibold text-gray-900">
                {stats.totalUsers}
              </p>
              <p className="text-sm text-gray-500">
                {stats.activeUsers} active
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="bg-white rounded-lg shadow p-6"
        >
          <div className="flex items-center">
            <div className="p-3 rounded-lg bg-green-100">
              <DollarSign className="h-6 w-6 text-green-600" />
            </div>
            <div className="ml-4">
              <h2 className="text-sm font-medium text-gray-500">Platform Revenue</h2>
              <p className="text-2xl font-semibold text-gray-900">
                ${stats.totalRevenue.toFixed(2)}
              </p>
              <p className="text-sm text-gray-500">
                Last {dateRange} days
              </p>
            </div>
          </div>
        </motion.div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="bg-white rounded-lg shadow p-6"
        >
          <div className="flex items-center">
            <div className="p-3 rounded-lg bg-purple-100">
              <TrendingUp className="h-6 w-6 text-purple-600" />
            </div>
            <div className="ml-4">
              <h2 className="text-sm font-medium text-gray-500">Conversion Rate</h2>
              <p className="text-2xl font-semibold text-gray-900">
                {stats.conversionRate.toFixed(1)}%
              </p>
              <p className="text-sm text-gray-500">
                Users to sales
              </p>
            </div>
          </div>
        </motion.div>
      </div>

      {/* Recent Activity Feed */}
      <div className="bg-white rounded-lg shadow p-6 mb-8">
        <h2 className="text-lg font-semibold text-gray-900 mb-4">Recent Activity</h2>
        <div className="space-y-4">
          {stats.recentActivity.map((activity, index) => (
            <div key={index} className="flex items-center gap-4">
              <div className={`p-2 rounded-lg ${
                activity.type === 'signup' ? 'bg-blue-100' : 'bg-green-100'
              }`}>
                {activity.type === 'signup' ? (
                  <Users className={`h-4 w-4 ${
                    activity.type === 'signup' ? 'text-blue-600' : 'text-green-600'
                  }`} />
                ) : (
                  <DollarSign className="h-4 w-4 text-green-600" />
                )}
              </div>
              <div>
                <p className="text-sm text-gray-900">
                  {activity.type === 'signup' ? (
                    `${activity.user.display_name || activity.user.email} joined`
                  ) : (
                    `${activity.user.display_name || activity.user.email} earned $${activity.amount?.toFixed(2)}`
                  )}
                </p>
                <p className="text-xs text-gray-500">
                  {format(new Date(activity.timestamp), 'MMM d, h:mm a')}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Users Table */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="p-6 border-b">
          <div className="flex items-center justify-between">
            <h2 className="text-lg font-semibold text-gray-900">User Management</h2>
            <div className="flex items-center gap-4">
              <div className="relative">
                <Search className="h-5 w-5 text-gray-400 absolute left-3 top-1/2 transform -translate-y-1/2" />
                <input
                  type="text"
                  placeholder="Search users..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  User
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Joined
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Last Active
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Links
                </th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                  Earnings
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {filteredUsers.map((user) => (
                <tr key={user.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        {user.display_name || 'Not Set'}
                      </div>
                      <div className="text-sm text-gray-500">{user.email}</div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {format(new Date(user.created_at), 'MMM d, yyyy')}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {user.last_active ? format(new Date(user.last_active), 'MMM d, yyyy') : 'Never'}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    {user.links_created}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                    ${user.total_earnings.toFixed(2)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}