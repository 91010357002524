import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DollarSign, Share2, TrendingUp, Shield, Zap, Gift, Users, ArrowRight, Sparkles, MessageSquare } from 'lucide-react';
import { motion } from 'framer-motion';
import { FeedbackDialog } from '../components/FeedbackDialog';
import { CountUpAnimation } from '../components/CountUpAnimation';
import { supabase } from '../lib/supabase';
import { log } from '../lib/logger';

interface PlatformStats {
  userCount: number;
  linkCount: number;
  totalEarnings: number;
}

export function LandingPage() {
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [stats, setStats] = useState<PlatformStats>({
    userCount: 0,
    linkCount: 0,
    totalEarnings: 0
  });
  const [loading, setLoading] = useState(true);

  const fetchStats = async () => {
    try {
      // Get total users count using public policy
      const { count: userCount } = await supabase
        .from('users')
        .select('*', { count: 'exact', head: true });

      // Get total links count using public policy
      const { count: linkCount } = await supabase
        .from('affiliate_links')
        .select('*', { count: 'exact', head: true });

      // Get total earnings using public policy
      const { data: earnings } = await supabase
        .from('earnings')
        .select('amount');

      // Calculate totals
      const totalEarnings = earnings?.reduce((sum, earning) => {
        return sum + (Number(earning.amount) || 0);
      }, 0) || 0;

      // Update stats
      setStats({
        userCount: userCount || 0,
        linkCount: linkCount || 0,
        totalEarnings
      });

      log('info', 'Platform stats updated', {
        users: userCount,
        links: linkCount,
        earnings: totalEarnings
      });
    } catch (error) {
      log('error', 'Failed to fetch platform stats', { error });
      // Keep existing stats on error
    } finally {
      setLoading(false);
    }
  };

  // Initial fetch
  useEffect(() => {
    fetchStats();
  }, []);

  // Set up real-time subscriptions
  useEffect(() => {
    // Create a single channel for all subscriptions
    const channel = supabase.channel('db-changes')
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'users'
      }, () => fetchStats())
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'affiliate_links'
      }, () => fetchStats())
      .on('postgres_changes', {
        event: '*',
        schema: 'public',
        table: 'earnings'
      }, () => fetchStats())
      .subscribe();

    // Cleanup subscription
    return () => {
      channel.unsubscribe();
    };
  }, []);

  return (
    <div className="bg-white overflow-hidden">
      {/* Floating Bubbles */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-purple-200 rounded-full opacity-20 animate-float" style={{ animationDelay: '0s' }} />
        <div className="absolute top-20 -left-20 w-60 h-60 bg-blue-200 rounded-full opacity-20 animate-float" style={{ animationDelay: '2s' }} />
        <div className="absolute bottom-40 right-20 w-40 h-40 bg-indigo-200 rounded-full opacity-20 animate-float" style={{ animationDelay: '4s' }} />
      </div>

      {/* Hero Section */}
      <div className="relative overflow-hidden bg-gradient-to-br from-blue-600 via-indigo-500 to-purple-600 animate-gradient bg-[length:400%_400%]">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 py-16 sm:py-24 lg:py-32 px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center max-w-2xl mx-auto"
            >
              <h1 className="text-4xl tracking-tight font-extrabold text-white sm:text-5xl md:text-6xl">
                <span className="block">Share cool stuff.</span>
                <span className="block text-blue-200">Make actual money 🤑</span>
              </h1>
              <p className="mt-3 text-base text-blue-100 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl">
                You can just do things... you're already telling friends about awesome products on TikTok, Instagram, and X. Might as well get paid for it. It takes like 1 min to start.
              </p>
              <div className="mt-5 sm:mt-8 flex justify-center">
                <motion.div
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className="rounded-2xl shadow-lg overflow-hidden"
                >
                  <Link
                    to="/signup"
                    className="w-full flex items-center justify-center px-8 py-4 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 hover:from-blue-600 hover:via-indigo-600 hover:to-purple-600 text-white font-medium rounded-2xl transition-all duration-200 md:text-lg md:px-10"
                  >
                    Let's Get This Money! <ArrowRight className="ml-2 h-5 w-5" />
                  </Link>
                </motion.div>
              </div>

              {/* Real-time Stats */}
              <div className="mt-8 bg-white/10 backdrop-blur-lg rounded-2xl p-6 text-white">
                <div className="flex items-center justify-between flex-wrap gap-4">
                  <div>
                    <p className="text-2xl font-bold">
                      <CountUpAnimation end={stats.userCount} />
                    </p>
                    <p className="text-blue-200">Earners</p>
                  </div>
                  <div>
                    <p className="text-2xl font-bold">
                      <CountUpAnimation end={stats.linkCount} />
                    </p>
                    <p className="text-blue-200">Links Generated</p>
                  </div>
                  <div>
                    <p className="text-2xl font-bold">
                      <CountUpAnimation prefix="$" end={stats.totalEarnings} decimals={2} />
                    </p>
                    <p className="text-blue-200">Earned so far</p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </div>

      {/* How It Works Section */}
      <div className="py-16 bg-white relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase flex items-center justify-center gap-2">
              <Sparkles className="h-5 w-5" />
              How It Works
              <Sparkles className="h-5 w-5" />
            </h2>
            <p className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
              Three simple steps to start earning
            </p>
          </motion.div>

          <div className="mt-12">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-3">
              {[
                {
                  icon: Users,
                  title: "1. Sign Up 🚀",
                  description: "Create your free account in 60 seconds"
                },
                {
                  icon: Share2,
                  title: "2. Share Products ✨",
                  description: "Find products you already love and share your special link"
                },
                {
                  icon: DollarSign,
                  title: "3. Ka-ching! 💰",
                  description: "Get paid when people buy through your links"
                }
              ].map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="relative"
                >
                  <div className="bg-white rounded-3xl p-8 shadow-lg hover:shadow-xl transition-shadow duration-300 border border-gray-100">
                    <div className="flex items-center justify-center h-16 w-16 rounded-2xl bg-gradient-to-r from-blue-500 to-indigo-600 mx-auto">
                      <step.icon className="h-8 w-8 text-white" />
                    </div>
                    <h3 className="mt-4 text-xl font-bold text-gray-900 text-center">{step.title}</h3>
                    <p className="mt-2 text-base text-gray-500 text-center">
                      {step.description}
                    </p>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-16 bg-gray-50 relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="lg:text-center"
          >
            <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Features</h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              Everything you need ✨
            </p>
          </motion.div>

          <div className="mt-10">
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              {[
                {
                  icon: Zap,
                  title: "Instant Link Generation",
                  description: "Create affiliate links instantly for any product in our catalog. No approval process needed."
                },
                {
                  icon: Gift,
                  title: "Competitive Commission Rates",
                  description: "Earn commission on qualifying sales through your unique affiliate links."
                },
                {
                  icon: TrendingUp,
                  title: "Real-time Analytics",
                  description: "Track clicks, conversions, and earnings in real-time. Know exactly how your links perform."
                },
                {
                  icon: Shield,
                  title: "Secure Payments",
                  description: "Get paid monthly via Stripe when you reach the minimum payout threshold."
                }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="bg-white rounded-3xl p-8 shadow-lg hover:shadow-xl transition-all duration-300"
                >
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <div className="flex items-center justify-center h-12 w-12 rounded-2xl bg-gradient-to-r from-blue-500 to-indigo-600 text-white">
                        <feature.icon className="h-6 w-6" />
                      </div>
                    </div>
                    <div className="ml-4">
                      <h3 className="text-lg font-bold text-gray-900">{feature.title}</h3>
                      <p className="mt-2 text-base text-gray-500">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gradient-to-r from-blue-600 to-indigo-700 relative overflow-hidden">
        <div className="absolute inset-0 overflow-hidden pointer-events-none opacity-20">
          <div className="absolute -top-40 -right-40 w-80 h-80 bg-white rounded-full animate-float" />
          <div className="absolute bottom-20 -left-20 w-60 h-60 bg-white rounded-full animate-float" style={{ animationDelay: '2s' }} />
        </div>
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8 relative">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
              <span className="block">Ready to start earning? 🚀</span>
              <span className="block">Join EasyEarn today. ✨</span>
            </h2>
            <p className="mt-4 text-lg leading-6 text-blue-100">
              Turn your social influence into potential income by sharing what you love.
            </p>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Link
                to="/signup"
                className="mt-8 w-full inline-flex items-center justify-center px-8 py-4 bg-gradient-to-r from-blue-500 via-indigo-500 to-purple-500 hover:from-blue-600 hover:via-indigo-600 hover:to-purple-600 text-white font-medium rounded-2xl transition-all duration-200 sm:w-auto"
              >
                Let's Get This Money! <ArrowRight className="ml-2 h-5 w-5" />
              </Link>
            </motion.div>
          </motion.div>
        </div>
      </div>

      {/* Feedback Dialog */}
      <FeedbackDialog
        isOpen={isFeedbackOpen}
        onClose={() => setIsFeedbackOpen(false)}
      />
    </div>
  );
}