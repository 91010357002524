import React, { useState, useEffect } from 'react';
import { Shield, Key, Database, AlertCircle, Loader2 } from 'lucide-react';
import { motion } from 'framer-motion';
import { supabase } from '../lib/supabase';
import { log } from '../lib/logger';
import { useNavigate } from 'react-router-dom';

const SETUP_PASSWORD = 'easyearn-setup-2025'; // This should be changed after use

export function AdminSetupPage() {
  const navigate = useNavigate();
  const [password, setPassword] = useState('');
  const [authenticated, setAuthenticated] = useState(false);
  const [currentUser, setCurrentUser] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);

  useEffect(() => {
    checkCurrentUser();
  }, []);

  const handlePasswordSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (password === SETUP_PASSWORD) {
      setAuthenticated(true);
    } else {
      setError('Invalid setup password');
    }
  };

  const checkCurrentUser = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data } = await supabase
          .from('users')
          .select('*')
          .eq('id', user.id)
          .single();
        setCurrentUser(data);
      } else {
        navigate('/login');
      }
    } catch (error) {
      log('error', 'Failed to check current user', { error });
      setError('Failed to verify user authentication');
    } finally {
      setLoading(false);
    }
  };

  const promoteToAdmin = async () => {
    if (!currentUser) {
      setError('No user logged in');
      return;
    }

    setActionLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const { error } = await supabase
        .from('users')
        .update({ is_admin: true })
        .eq('id', currentUser.id);

      if (error) throw error;

      log('info', 'User promoted to admin', { userId: currentUser.id });
      setSuccess('Successfully promoted to admin! You can now access /admin');
      
      // Update local state
      setCurrentUser({ ...currentUser, is_admin: true });
      
      // Redirect to admin dashboard after a short delay
      setTimeout(() => {
        navigate('/admin');
      }, 2000);
    } catch (error) {
      log('error', 'Failed to promote user to admin', { error });
      setError('Failed to update admin status');
    } finally {
      setActionLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center">
        <div className="flex items-center gap-3">
          <Loader2 className="h-6 w-6 animate-spin text-blue-500" />
          <span className="text-gray-600">Verifying authentication...</span>
        </div>
      </div>
    );
  }

  if (!authenticated) {
    return (
      <div className="min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <div className="max-w-md w-full">
          <form onSubmit={handlePasswordSubmit} className="bg-white rounded-lg shadow-lg p-6">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-blue-100 rounded-lg">
                <Shield className="h-6 w-6 text-blue-600" />
              </div>
              <h1 className="text-xl font-bold text-gray-900">Admin Setup</h1>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Setup Password
              </label>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-3 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                required
              />
            </div>

            {error && (
              <div className="mb-4 text-red-600 text-sm flex items-center gap-2">
                <AlertCircle className="h-4 w-4" />
                {error}
              </div>
            )}

            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors"
            >
              Access Setup
            </button>
          </form>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 p-4">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          <div className="p-6 border-b border-gray-200">
            <div className="flex items-center gap-3">
              <div className="p-2 bg-blue-100 rounded-lg">
                <Key className="h-6 w-6 text-blue-600" />
              </div>
              <h1 className="text-xl font-bold text-gray-900">Admin Access Setup</h1>
            </div>
          </div>

          <div className="p-6 space-y-8">
            {/* Database Info */}
            <div>
              <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2 mb-4">
                <Database className="h-5 w-5 text-blue-500" />
                Database Structure
              </h2>
              <div className="bg-gray-50 rounded-lg p-4 font-mono text-sm">
                <pre className="whitespace-pre-wrap">
{`Table: public.users
- id: uuid (Primary Key, references auth.users)
- email: text
- display_name: text
- is_admin: boolean (This controls admin access)
- created_at: timestamp with time zone
- updated_at: timestamp with time zone

Current Connection:
URL: ${import.meta.env.VITE_SUPABASE_URL}
`}
                </pre>
              </div>
            </div>

            {/* Current User */}
            <div>
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Current User</h2>
              <div className="bg-gray-50 rounded-lg p-4">
                <div className="space-y-2">
                  <p><strong>ID:</strong> {currentUser?.id}</p>
                  <p><strong>Email:</strong> {currentUser?.email}</p>
                  <p><strong>Admin Status:</strong> {currentUser?.is_admin ? 'Yes' : 'No'}</p>
                </div>
              </div>
            </div>

            {/* Action Buttons */}
            <div className="flex items-center gap-4">
              <button
                onClick={promoteToAdmin}
                disabled={actionLoading || currentUser?.is_admin}
                className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
              >
                {actionLoading ? (
                  <>
                    <Loader2 className="h-5 w-5 animate-spin" />
                    Processing...
                  </>
                ) : (
                  'Promote to Admin'
                )}
              </button>
            </div>

            {success && (
              <div className="bg-green-50 text-green-800 p-4 rounded-lg flex items-center gap-2">
                <Shield className="h-5 w-5" />
                {success}
              </div>
            )}

            {error && (
              <div className="bg-red-50 text-red-800 p-4 rounded-lg flex items-center gap-2">
                <AlertCircle className="h-5 w-5" />
                {error}
              </div>
            )}

            {/* Manual SQL */}
            <div>
              <h2 className="text-lg font-semibold text-gray-900 mb-4">Manual SQL Command</h2>
              <div className="bg-gray-50 rounded-lg p-4 font-mono text-sm">
                <pre className="whitespace-pre-wrap">
{`-- Run this in Supabase SQL Editor:
UPDATE public.users
SET is_admin = true
WHERE id = '${currentUser?.id}';`}
                </pre>
              </div>
            </div>

            <div className="bg-yellow-50 p-4 rounded-lg">
              <p className="text-yellow-800">
                ⚠️ For security, remove this setup page after promoting your admin user.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}